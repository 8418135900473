<template>
  <div>
    <v-stepper non-linear>
      <v-stepper-header>
        <v-stepper-step editable step="1">
          SuS auswählen
          <small v-if="students.size > 0"> {{ students.size }} gewählt </small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step editable step="2">
          Portfolio auswählen
          <small> {{ portfolio.typeDescription }} </small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" editable> Prüfen und erzeugen </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row>
            <v-col class="text-center"> Kandidaten </v-col>
            <v-col class="text-center"> Ausgewählte SuS </v-col>
          </v-row>
          <v-row>
            <v-col>
              <PersonInput
                v-model="candidate"
                group="student"
                clearable
                label="einzelne SuS"
                @input="add(candidate)"
              />
              <CourseInput
                v-if="term"
                label="Kurs wählen"
                :startDate="term.startDate"
                :endDate="term.endDate"
                v-model="course"
                clearable
              />
              <SchoolClassInput
                label="Klasse wählen"
                v-model="schoolClass"
                clearable
              />
              <v-chip
                v-for="student in candidates"
                :key="'c-' + student.id"
                @click="add(student)"
                class="ma-1"
              >
                <v-avatar left>
                  <PortraitImage :value="student" />
                </v-avatar>
                <span> {{ student.firstName }} {{ student.lastName }}</span>
              </v-chip>
              <v-chip
                v-if="candidates && candidates.length > 0"
                @click="addAll"
                color="success"
                >alle hinzufügen</v-chip
              >
            </v-col>
            <v-divider vertical />
            <v-col>
              <v-chip
                v-for="student in students.values()"
                :key="'s-' + student.id"
                @click="remove(student)"
                class="ma-1"
              >
                <v-avatar left>
                  <PortraitImage :value="student" />
                </v-avatar>
                <span> {{ student.firstName }} {{ student.lastName }}</span>
              </v-chip>
              <v-chip
                v-if="students && students.size > 0"
                @click="removeAll"
                color="error"
                >alle entfernen</v-chip
              >
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <RowItem align="center" title="Typ">
            <v-autocomplete
              v-model="portfolio.type"
              :items="types"
              item-text="description"
              item-value="id"
              return-object
              @change="loadType"
            ></v-autocomplete>
          </RowItem>
          <RowItem align="center" title="Titel">
            <v-text-field v-model="portfolio.title" />
          </RowItem>
          <RowItem align="center" title="erstellt von">
            <PersonInput
              group="employee"
              clearable
              v-model="portfolio.creator"
            />
          </RowItem>
          <RowItem align="center" title="Comment" v-if="hasComment">
            <v-text-field v-model="portfolio.comment" />
          </RowItem>
          <RowItem title="Text">
            <v-textarea
              outlined
              v-model="portfolio.text"
              :hint="'{{comment}}, {{student}}, {{division}}, {{mayear}} und {{male|female}} werden auf dem Server automatisch ersetzt'"
              persistent-hint
            />
          </RowItem>
          <RowItem align="center" title="versteckt">
            <v-checkbox v-model="portfolio.hidden" />
          </RowItem>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col class="text-center"> Für diese SuS </v-col>
            <v-col class="text-center"> dieses Portfolio erstellen </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-chip
                v-for="student in students.values()"
                :key="'p-' + student.id"
                class="ma-1"
              >
                <v-avatar left>
                  <PortraitImage :value="student" />
                </v-avatar>
                <span> {{ student.firstName }} {{ student.lastName }}</span>
              </v-chip>
              <v-alert border="left" type="error" v-if="students.size < 1"
                >Keine SuS gewählt!</v-alert
              >
            </v-col>
            <v-divider vertical />
            <v-col>
              <RowItem align="center" title="Titel">
                {{ portfolio.title }}
                <span v-if="!portfolio.title"
                  ><v-icon color="error">mdi-alert</v-icon></span
                >
              </RowItem>
              <RowItem align="center" title="erstellt von">
                <PersonItem
                  v-if="portfolio.creator"
                  :value="portfolio.creator"
                />
                <span v-else><v-icon color="error">mdi-alert</v-icon></span>
              </RowItem>
              <RowItem align="center" title="Comment" v-if="hasComment">
                {{ portfolio.comment }}
                <span v-if="hasComment && !portfolio.comment"
                  ><v-icon color="error">mdi-alert</v-icon></span
                >
              </RowItem>
              <RowItem title="Text">
                {{ renderedText }}
              </RowItem>
              <RowItem align="center" title="versteckt">
                <v-checkbox disabled :value="portfolio.hidden" />
              </RowItem>
              <RowItem title="">
                <v-btn
                  color="primary"
                  @click="generate"
                  :loading="creating"
                  :disabled="!generatable"
                >
                  erzeugen
                </v-btn>
              </RowItem>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <router-view />
  </div>
</template>

<script>
import CourseInput from "common/components/CourseInput";
import PersonItem from "@/components/PersonItem";
import PersonInput from "common/components/PersonInput.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import RowItem from "@/components/RowItem";
import SchoolClassInput from "@/components/SchoolClassInput";
export default {
  name: "Creation",
  props: ["search", "term"],
  components: {
    CourseInput,
    PersonItem,
    PersonInput,
    PortraitImage,
    RowItem,
    SchoolClassInput,
  },
  data: () => ({
    creating: false,
    candidates: [],
    candidate: {},
    course: null,
    loading: false,
    portfolio: {
      title: "",
      text: "",
      comment: "",
      type: 0,
      typeDescription: "",
      creator: {},
    },
    schoolClass: {},
    students: [],
    types: [],
  }),
  computed: {
    hasComment() {
      return (
        this.portfolio.type &&
        this.portfolio.text &&
        this.portfolio.text.includes("{{comment}}")
      );
    },
    renderedText() {
      if (!this.portfolio || !this.portfolio.text) {
        return "–";
      }
      if (this.portfolio.comment) {
        return this.portfolio.text.replace(
          "{{comment}}",
          this.portfolio.comment
        );
      }
      return this.portfolio.text;
    },
    generatable() {
      return (
        this.portfolio.type &&
        this.students.length > 0 &&
        this.portfolio.creator &&
        (!this.hasComment || this.portfolio.comment)
      );
    },
  },
  watch: {
    async course() {
      if (this.course) {
        let data = await this.apiGet({
          resource: "course/course",
          id: this.course.id,
        });
        this.candidates = data.students;
      }
    },
    async schoolClass() {
      if (this.schoolClass) {
        let data = await this.apiGet({
          resource: "schoolclass/schoolclass",
          id: this.schoolClass.id,
        });
        this.candidates = data.students;
      }
    },
  },
  methods: {
    add(student) {
      if (!this.students.includes(student)) {
        this.students.push(student);
        this.candidate = {};
      }
    },
    addAll() {
      this.candidates.forEach((candidate) => {
        if (!this.students.includes(candidate)) {
          this.students.push(candidate);
        }
      });
    },
    async generate() {
      this.creating = true;
      const items = Array.from(this.students);
      await Promise.all(
        items.map(async (student) => {
          const portfolio = {
            creator: { id: this.portfolio.creator.id },
            title: this.portfolio.title,
            text: this.portfolio.text,
            comment: this.portfolio.comment,
            student: { id: student.id },
            type: { id: this.portfolio.type },
            hidden: this.portfolio.hidden,
          };
          await this.apiPost({
            resource: "portfolio/portfolio",
            data: portfolio,
          });
        })
      );
      this.creating = false;
      this.$root.showSuccess("Portfolios erzeugt!");
      this.getData();
    },
    async getData() {
      this.loading = true;
      this.types = await this.apiList({ resource: "portfolio/type" });
      this.loading = false;
    },
    async loadType() {
      this.loading = true;
      const type = await this.apiGet({
        resource: "portfolio/type",
        id: this.portfolio.type.id,
      });
      this.portfolio.title = type.title;
      this.portfolio.text = type.text;
      this.portfolio.type = type.id;
      this.portfolio.typeDescription = type.description;
      this.portfolio.creator = type.creator;
      this.portfolio.hidden = type.hidden;
      this.loading = false;
    },
    remove(student) {
      const index = this.students.indexOf(student);
      if (index >= 0) {
        this.students.splice(index, 1);
      }
    },
    removeAll() {
      this.students = [];
    },
  },
  created() {
    this.students = [];
    this.getData();
  },
};
</script>
