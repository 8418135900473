<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :disabled="loading"
    :loading="loading"
    :filter="filterItems"
    :items="items"
    item-text="name"
    item-value="id"
    @change="select"
    return-object
  >
    <template v-slot:selection="data">
      {{ data.item.code }} ({{ data.item.teacher.code }})
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title
          >{{ data.item.code }} ({{
            data.item.teacher ? data.item.teacher.code : '–'
          }})</v-list-item-title
        >
        <v-list-item-subtitle>
          {{ data.item.majorSubjects.join(', ') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  name: 'SchoolClassInput',
  props: ['value'],
  data() {
    return {
      loading: true,
      items: [],
    };
  },
  watch: {
    async value() {
      if (this.value && this.value.id) {
        if (!this.items.some((person) => person.id === this.value.id)) {
          this.items.push(this.value);
        }
      }
    },
    group() {
      this.getData();
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: 'schoolclass/schoolclass' });
      this.loading = false;
    },
    filterItems: (item, queryText) => {
      return (
        (item.code &&
          item.code.toLowerCase().includes(queryText.toLowerCase())) ||
        (item.teacher &&
          item.teacher.code.toLowerCase().includes(queryText.toLowerCase()))
      );
    },
    remove() {
      this.$emit('input', null);
    },
    select($event) {
      this.$emit('input', $event);
    },
  },
  async created() {
    this.getData();
  },
};
</script>
